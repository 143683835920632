
import {Component, Vue, Prop, Watch, Model} from 'vue-property-decorator';
interface IStats {
    collect: number;
    spin: number;
    show: number;
    reject: number;
    revenue: number;
}
@Component({

})
export default class QuickStats extends Vue {
    @Prop(Array) public appStats!: IStats[];
    @Prop(Boolean) public freeSpin!: boolean;

    private tab: number = 0;
    private shown: number = 0;
    private collected: number = 0;
    private spinned: number = 0;
    private rejected: number = 0;
    private revenue: number = 0;
    private conversionRate: number = 0;
    private shopType: string = '';
    private created() {
        this.shopType = sessionStorage.getItem('type') ?? 'Standalone';
        this.updateData();
    }
    private updateData() {
        this.appStats.forEach((el) => {
            this.shown += el.show;
            this.collected += el.collect;
            this.spinned += el.spin;
            this.rejected += el.reject;
            this.revenue += el.revenue;
        });
        // @ts-ignore
        if (this.shown > 0 && this.spinned > 0) {
            // @ts-ignore
            this.conversionRate = Math.round(this.spinned / this.shown * 100);
        }
    }
    @Watch('appStats')
    private onPropertyChanged() {
        this.shown = 0;
        this.collected = 0;
        this.spinned = 0;
        this.rejected = 0;
        this.revenue = 0;
        this.conversionRate = 0;
        this.updateData();
    }

    get revenueSum() {
        if (this.shopType !== 'Standalone') {
            const moneyWithCurrencyFormat = sessionStorage.getItem('moneyWithCurrencyFormat');
            if (moneyWithCurrencyFormat != null) {
                const patt = /{{\s*[a-zA-Z0-9_]+?\s*}}/g;
                return moneyWithCurrencyFormat.replace(patt, this.revenue.toString())
                    .replace('<span class=money>', '').replace('</span>', '');
            }
        }
        return '';
    }
}
