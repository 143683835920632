
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ErrorHandler } from './helpers/errorHandler';
import LanguageSwitcher from './components/LanguageSwitcher.vue';
import { Trans } from './plugins/translation';
import i18n from './plugins/i18n';
import { ShopStats } from './models/ShopStats';
import ToolbarCircle from '@/components/ToolbarCircle.vue';
import PricingTiersDialog from '@/components/dialogs/PricingTiersDialog.vue';
import { PricingPlans } from './helpers/pricingPlan';
import ReviewDialog2 from '@/components/dialogs/ReviewDialog2.vue';
import DummyApp from '@/views/DummyApp.vue';
import NewChargeDialog from '@/components/dialogs/NewChargeDialog.vue';

@Component({
  components: {
    LanguageSwitcher,
    ToolbarCircle,
    PricingTiersDialog,
    ReviewDialog2,
    DummyApp,
    NewChargeDialog
  },
})
export default class App extends Vue {
  private dummy: boolean = false;
  private clipped: boolean = true;
  private drawer: boolean = true;
  private miniVariant: boolean = false;
  private right: boolean = true;
  private title: string = '';
  private hidden: boolean = false;
  private showDialog: boolean = false;
  private showPricingDialog: boolean = false;
  private showReviewDialog: boolean = false;
  private actionLoading: boolean = false;
  private statsLoading: boolean = false;
  private error: boolean = false;
  private profileMenu: boolean = false;
  private shopType: any;
  private toolbarStatus: boolean = false;
  private toolbarArrowRotation: number = 180;
  private showPulltab: boolean = true;
  private rules: any = {
      required (value) {
        if (value && value.trim() === '') {
            return i18n.t('validation.required');
        }
        return !!value || i18n.t('validation.required');
      },
      password: value => {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        return (
          pattern.test(value) ||
              i18n.t('validation.password')
        );
      }
  };
  private showCurrentPassword: boolean = false;
  private showNewPassword: boolean = false;
  private headerHeight = 75;
  private shopStats: ShopStats = {
    id: '',
    name: '',
    email: '',
    createdDate: new Date(),
    domain: '',
    show: 0,
    spin: 0,
    collect: 0,
    reject: 0,
    conversionRate: 0,
    revenue: 0,
    price: 0,
    currentShow: 0,
    currency: '',
    moneyWithCurrencyFormat: '',
    type: '',
    planName: '',
    hasShopPay: false
  };
  private changePasswordVM: object = {
    currentPassword: '',
    newPassword: '',
  };

  private menuItems = [
    { title: 'campaign.campaigns',                           icon: 'home',            link: '/campaignlist',  slot: '',     submenu: [], type: ['Standalone', 'Shopify'] },
    { title: 'export_emails.title',                          icon: 'assignment',      link: '/reports',       slot: '',     submenu: [], type: ['Standalone', 'Shopify'] },
    // { divider: true, submenu: [] },
    { title: 'website_settings.website_settings',            icon: 'settings',        link: '/website',       slot: '',     submenu: [], type: ['Standalone']  },
    // { title: i18n.t('dashboard.knowledge_base'),                     icon: 'mdi-notebook-outline',        link: '/knowledgebase', slot: '',     submenu: [], type: ['Standalone', 'Shopify'] },
    { title: 'dashboard.integrations',                       icon: 'link',            link: '/integration',   slot: '',     submenu: [], type: ['Standalone', 'Shopify'] },
    { title: 'setup_instructions.setup_instructions_title',  icon: 'school',          link: '/setup',         slot: '',     submenu: [], type: ['Standalone'] },
    { title: 'dashboard.admin',                              icon: 'mdi-account',     link: '/admin',         slot: '',     submenu: [], type: ['SuperAdmin'] }
  ];
  @Watch('$route', {
    immediate: true,
    deep: true,
  })
  private async onUrlChange(newVal: any) {
    this.showDialog = false;
    if (sessionStorage.getItem('token') != null && newVal.name === 'login') {
      this.dummy = true;
      await this.getShopsStats();
    } else if (sessionStorage.getItem('token') == null || newVal.name === 'activatesubscription' || newVal.name === 'login') {
      this.dummy = true;
      this.drawer = false;
      this.hidden = true;
    } else {
      this.drawer = true;
      this.hidden = false;
      this.title = sessionStorage.getItem('username') || '';
      if (this.shopStats.id === '' && newVal.name !== 'privacypolicy') {
        await this.getShopsStats();
      }
    }

    if (this.$route.path === '/campaignlist') {
      this.dummy = false;
      this.toolbarStatus = false;
    } else {
      this.toolbarStatus = true;
    }

    if (this.$route.name === 'campaign') {
      this.dummy = false;
      this.showPulltab = false;
    } else {
      this.showPulltab = true;
    }
  }

  @Watch('toolbarStatus')
  private onPropertyChanged() {
      this.toolbarArrowRotation += 180;
  }

  private logout() {
    this.profileMenu = false;
    sessionStorage.clear();

    // @ts-ignore
    if (window.$crisp !== undefined) {
        // @ts-ignore
        window.$crisp.push(['do', 'session:reset']);
    }
    this.$router.push({
      name: 'login',
    });
  }

  private showDialogAction() {
    this.profileMenu = false;
    // @ts-ignore
    // TODO: find another soution
    // this.$refs.form.reset();
    this.showDialog = true;
  }

  private async changePassword() {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.actionLoading = true;
        try {
            await this.axios.post(`/api/account/ChangePassword`, this.changePasswordVM);
            this.$store.commit('showSuccessSnackbar',i18n.t('general.password_changed').toString())
            // @ts-ignore
            this.closeDialog()
        } catch (error: any) {
            new ErrorHandler().Server(error);
            this.error = true;
        } finally {
            this.actionLoading = false;
        }
    }
  }

  private goToProfile() {
    this.profileMenu = false;
    this.$router.push({
      name: 'profile',
    });
  }

  private closeDialog() {
    // @ts-ignore
    this.changePasswordVM.newPassword = '';
    // @ts-ignore
    this.changePasswordVM.currentPassword = '';
    // @ts-ignore
    this.$refs.form.resetValidation();

    this.showDialog = false;
  }

  private getSnackbar() {
    return this.$store.state.snackbar
  }

  private closeSnackbar() {
    this.$store.state.snackbar.showing = false;
  }

  private async created() {
    // @ts-ignore
    this.shopType = sessionStorage.getItem('type') ?? 'Standalone';
    Trans.changeLanguage(Trans.getCookie('wheelio-lang'));
    const token = sessionStorage.getItem('token');
    if (token != null) {
      this.$configureCrisp();
    }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.miniVariant = true;
    }
    this.changeMenuLang();
  }

  // private async getReport(reportId: string, userId: string) {
  //   try {
  //       const response = await this.axios.get(`/api/services/getreport/${reportId}/${userId}`);
  //       if (response.status === 200) {
  //         this.$store.commit('showSuccessSnackbar','Your report has been generated successfuly. Click here to download it.')
  //         // @ts-ignore
  //         if ('getStoredData' in this.$refs?.routerView && 'getReports' in this.$refs.routerView) {
  //           // @ts-ignore
  //           this.$refs.routerView.getStoredData();
  //           // @ts-ignore
  //           this.$refs.routerView.getReports();
  //         }
  //       }
  //   } catch (error) {
  //       // new ErrorHandler().Server(error);
  //   }
  // }

  private async getShopsStats() {
    try {
      this.statsLoading = true;
      const response = await this.axios.get(`/api/campaign/getshopstats`);
      this.shopStats = response.data.data;
      if (this.shopStats.planName === 'migration') {
        this.$store.state.newChargeDialog.disableFeatures = true;
      }
             
      if (this.shopStats.hasShopPay) {
        this.$crispUserEvent('user:hasShopPayEnabled');
      }

      this.$store.state.shopStats = this.shopStats;
      // @ts-ignore
      window.$crisp.push(['set', 'session:data', [[['shop-impression', this.shopStats.show]]]]);
      // @ts-ignore
      window.$crisp.push(['set', 'session:data', [[['shop-collected', this.shopStats.spin]]]]);

    } catch (error: any) {
      new ErrorHandler().Server(error);
    } finally {
      this.statsLoading = false;
      this.openReviewDialog();
    }
  }

  private goToUrl(url: string) {
      window.open(url);
  }

  get campaignListActive() {
    if (this.$route.path === '/campaignlist') {
      this.headerHeight = 320;
      return true;
    }
    this.headerHeight = 75;
    return false;
  }

  private toggleToolbar(){
    // this.toolbarArrowRotation += 180;
    this.toolbarStatus = !this.toolbarStatus;
  }

  get menuItemsForType() {
    const items = this.menuItems.filter(item => {
        return item.type.indexOf(this.shopType) !== -1 || (this.isSuperAdmin && this.isStandAlone && item.type.indexOf('SuperAdmin') !== -1);
      });
    return items;
  }

  get dummyMenuItems() {
    const items = this.menuItems.filter(item => {
        return item.type.indexOf(this.shopType) !== -1;
    });
    return items;
  }

  get isStandAlone() {
    return this.shopType === 'Standalone';
  }

  private goToHome() {
    if (this.$router.app.$route.name !== 'campaignlist') {
      this.$router.push({
        name: 'campaignlist',
      });
    }
  }

  private setActive(item: object) {
    // @ts-ignore
    return item.link === '/campaignlist' && this.$route.name === 'campaign' ? 'v-btn--active' : '';
  }

  get CampaignEditForm() {
    return this.$route.name === 'campaign';
  }

  get AdminPage() {
    return this.$route.name === 'admin';
  }

  private async login() {
    const domain = sessionStorage.getItem('myshopify_domain');

    if (domain) {
      try {
        this.actionLoading = true;
        // @ts-ignore
        const response = await this.axios.get(`/shopify/login?shop=${domain}`);
        // @ts-ignore
        window.top.location = response.data.data;
      } catch (error: any) {
          new ErrorHandler().Server(error);
          this.actionLoading = false;
      }
    }
  }

  get revenue() {
    if (this.shopType !== 'Standalone' && this.shopStats.moneyWithCurrencyFormat != null) {
      sessionStorage.setItem('moneyWithCurrencyFormat', this.shopStats.moneyWithCurrencyFormat);
      const patt = /{{\s*[a-zA-Z0-9_]+?\s*}}/g;
      return this.shopStats.moneyWithCurrencyFormat.replace(patt, this.shopStats.revenue.toString())
        .replace('<span class=money>', '').replace('</span>', '');
    }
    return '';
  }

  private changeMenuLang() {
    // this.menuItems = [
    //   { title: 'campaign.campaigns',                           icon: 'home',            link: '/campaignlist',  slot: '',     submenu: [], type: ['Standalone', 'Shopify'] },
    //   { title: i18n.t('export_emails.title'),                          icon: 'assignment',      link: '/reports',       slot: '',     submenu: [], type: ['Standalone', 'Shopify'] },
    //   // { divider: true, submenu: [] },
    //   { title: i18n.t('website_settings.website_settings'),            icon: 'settings',        link: '/website',       slot: '',     submenu: [], type: ['Standalone']  },
    //   // { title: i18n.t('dashboard.knowledge_base'),                     icon: 'mdi-notebook-outline',        link: '/knowledgebase', slot: '',     submenu: [], type: ['Standalone', 'Shopify'] },
    //   { title: i18n.t('dashboard.integrations'),                       icon: 'link',            link: '/integration',   slot: '',     submenu: [], type: ['Standalone', 'Shopify'] },
    //   { title: i18n.t('setup_instructions.setup_instructions_title'),  icon: 'school',          link: '/setup',         slot: '',     submenu: [], type: ['Standalone'] },
    //   { title: 'Admin',                                                icon: 'mdi-account',         link: '/admin',         slot: '',     submenu: [], type: ['SuperAdmin'] }
    // ]
  }

  get isSuperAdmin() {
    const username = sessionStorage.getItem('username') || '';
    // @ts-ignore
    if (process.env.VUE_APP_SUPER_ADMINS.includes(username)) {
      return true;
    }
    return false;
  }

  private openReviewDialog() {
    const pricingPlans = new PricingPlans();
    if (this.shopStats.revenue > pricingPlans.Tier1.price && !sessionStorage.getItem('showReviewDialog') && !Trans.getCookie('wheelio-review-cookie')) {
        // @ts-ignore
        window.$crisp.push(['set', 'session:data', [[['user-earned', this.shopStats.revenue], ['user-currency', this.shopStats.currency]]]]);
        sessionStorage.setItem('showReviewDialog', 'true');
        this.showReviewDialog = true;
        if(this.shopStats.currency === 'USD') {
          this.$crispUserEvent('user:madeMoreThanTier1');
        }
    } else if (this.shopStats.revenue > 0) {
        // @ts-ignore
        window.$crisp.push(['set', 'session:data', [[['user-earned', this.shopStats.revenue], ['user-currency', this.shopStats.currency]]]]);
        this.$crispUserEvent('user:madeMoney');
    }
  }
  private showUpgradeDialog() {
    this.$store.state.newChargeDialog.showing = true;
  }
  get showUpgradeButton() {
    return this.$store.state.newChargeDialog.disableFeatures;
  }
}
