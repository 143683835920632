
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Coupon, CouponSettings } from '../models/App';
import { ShopifyCoupon, valueType } from '../models/ShopifyCoupon';
import i18n from '../plugins/i18n';
import Tooltip from '@/components/Tooltip.vue';
import { AppType, ShopType } from '../helpers/enums';
import { ErrorHandler } from '@/helpers/errorHandler';

@Component({
    components: {
        Tooltip
    }
})
export default class AppSlices extends Vue {
    @Prop(Boolean) public hasChanged!: boolean;
    @Prop(Array) public appSlices!: Coupon[];
    @Prop(String) public appType!: string;
    @Prop(String) public currency!: string;
    @Prop(String) public planName!: string;
    @Prop(Boolean) public integrationsMailEnabled!: boolean;
    @Prop(Boolean) public integrationsSMSEnabled!: boolean;

    private cacheObject;
    private type: string = 'percentage';
    private applies: string = 'all_products';
    private minimumPurchase: string = 'none';
    private valueVisible: boolean = true;
    private suffix: string = '%';
    private placeHolder: string = '';
    private country: string = 'all';
    private dialog: boolean = false;
    private editedIndex: number = -1;
    private headers;
    private title;
    private items: [] = [];
    private isLoading = false;
    private search = '';
    private rules: any = {
        required: (value) => !!value || i18n.t('validation.required'),
        positiveInt: (value) => !value || (value >= 0 && value % 1 === 0) || i18n.t('validation.incorrectValue'),
        dateValidation: (value) => (value >= 0 && value !== '') || i18n.t('validation.incorrectValue'),
        lessThan101: (value) => !value || value <= 100 || i18n.t('validation.incorrectValue'),
        validLink: (value) => !value || this.validURLLink(value) || i18n.t('validation.invalidURL'),
        validLinkRedirect: (value) => !value || this.validURL(value) || i18n.t('validation.invalidURL'),
        preventWhiteSpace: (value) => !value || this.preventWhiteSpace(value) || i18n.t('validation.required'),
        preventEmoji: (value) => !value || this.preventEmoji(value) || i18n.t('validation.incorrectValue'),
        requiredCombobox (value) {
            if (value instanceof Array && value.length === 0) {
                return i18n.t('validation.required');
            }
            return !!value || i18n.t('validation.required');
        },
        greater0: (value) => !value || i18n.t('validation.greater0')
    };
    private headersWheelio = [
        {
            text: i18n.t('campaign.coupon_slices.slice'),
            sortable: false,
            value: 'slice',
            // width: '20',
        },
        {
            text: i18n.t('campaign.coupon_slices.type'),
            sortable: false,
            value: 'type',
            width: '150',
        },
        {
            text: i18n.t('campaign.coupon_slices.name'),
            sortable: false,
            value: 'name',
            // width: '300',
        },
        {
            text: i18n.t('campaign.coupon_slices.value'),
            sortable: false,
            value: 'code',
        },
        {
            text: i18n.t('campaign.coupon_slices.gravity'),
            sortable: false,
            align: 'right',
            value: 'gravity',
            // width: '150',
        },
        { text: '', value: 'actions', sortable: false }
    ];
    private headersRollie = [
        {
            text: i18n.t('campaign.coupon_slices.slot'),
            sortable: false,
            value: 'slice',
            // width: '20',
        },
        {
            text: i18n.t('campaign.coupon_slices.name'),
            sortable: false,
            value: 'name',
            // width: '300',
        },
        {
            text: i18n.t('campaign.coupon_slices.value'),
            sortable: false,
            value: 'code',
        },
        {
            text: i18n.t('campaign.coupon_slices.gravity'),
            sortable: false,
            align: 'right',
            value: 'gravity',
            // width: '150',
        },
        { text: '', value: 'actions', sortable: false }
    ];
    private headersScratchy = [
        {
            text: i18n.t('campaign.coupon_slices.ticket'),
            sortable: false,
            value: 'slice',
            // width: '20',
        },
        {
            text: i18n.t('campaign.coupon_slices.type'),
            sortable: false,
            value: 'type',
            width: '150',
        },
        {
            text: i18n.t('campaign.coupon_slices.name'),
            sortable: false,
            value: 'name',
            // width: '300',
        },
        {
            text: i18n.t('campaign.coupon_slices.value'),
            sortable: false,
            value: 'code',
        },
        {
            text: i18n.t('campaign.coupon_slices.gravity'),
            sortable: false,
            align: 'right',
            value: 'gravity',
            // width: '150',
        },
        { text: '', value: 'actions', sortable: false }
    ];
    private editedItem: Coupon = {
        id: '',
        name: '',
        type: '',
        code: '',
        gravity: 0,
        slice: 0,
        settings: '',
        redirect: ''
    };
    private CouponSettings: CouponSettings = {
        enabled: false,
        text: ''
    }
    private shopifyCoupon: ShopifyCoupon = {
        value: undefined,
        valueType: 'percentage',
        targetType: 'line_item',
        usageLimitEnable: false,
        usageLimit: undefined,
        allocationMethod: 'across',
        customerSelection: 'all',
        targetSelection: 'all',
        oncePerCustomer: false,
        expires: false,
        days: 0,
        hours: 0,
        minutes: 15,
        itemIds: [],
        prerequisiteSubtotalRange: null,
        prerequisiteQuantityRange: null,
        couponSettings: this.CouponSettings       
    };

   
    private sliceTypes = [
        { text: i18n.t('campaign.coupon_slices.coupon'), value: 'coupon', selected: true },
        { text: i18n.t('campaign.coupon_slices.link'), value: 'link',   selected: false}
    ];
    private timeSelection: string[] = [];

    private save() {
        if (this.editedItem.code) {
            this.editedItem.code = this.editedItem.code.trim();
        }
        // @ts-ignore
        if (this.$refs.form.validate()) {
            if (this.editedIndex > -1) {
                if (this.editedItem.type === 'dynamic') {
                    if (this.shopifyCoupon.targetType === 'shipping_line') {
                        this.shopifyCoupon.value = undefined;
                    }
                    if (!this.shopifyCoupon.usageLimitEnable) {
                        // @ts-ignore
                        this.shopifyCoupon.usageLimit = null;
                    }
                    if (this.applies !== 'all_products') {
                        this.shopifyCoupon.targetType = 'line_item';
                        this.shopifyCoupon.targetSelection = 'entitled';
                    }
                    if (this.minimumPurchase === 'none') {
                        this.shopifyCoupon.prerequisiteSubtotalRange = null;
                        this.shopifyCoupon.prerequisiteQuantityRange = null;                        
                    } else if (this.minimumPurchase === 'amount') {
                        this.shopifyCoupon.prerequisiteQuantityRange = null;
                    } else if (this.minimumPurchase === 'quantity') {
                        this.shopifyCoupon.prerequisiteSubtotalRange = null;
                    }
                    this.shopifyCoupon.couponSettings = this.CouponSettings;
                    this.editedItem.settings = JSON.stringify(this.shopifyCoupon);
                } else if (this.editedItem.type === 'coupon') {
                    this.editedItem.settings = JSON.stringify(this.CouponSettings);
                }
                Vue.set(this.appSlices, this.editedIndex, this.editedItem);
            }
            this.$emit('update:hasChanged', true);
            this.close();
        }
    }

    private close() {
        // @ts-ignore
        this.$refs.form.resetValidation();
        this.dialog = false;
    }

    private handleClick(value) {
        this.editItem(value)
    }

    private editItem(item: any) {
        this.editedIndex = this.appSlices.indexOf(item);
        this.editedItem = Object.assign({}, item);
 
        if (this.editedItem.type === 'dynamic' && this.editedItem.settings != null) {
            this.shopifyCoupon = JSON.parse(this.editedItem.settings);
            this.type = this.shopifyCoupon.targetType === 'shipping_line' ? this.shopifyCoupon.targetType : this.shopifyCoupon.valueType;
            this.typeChanged(this.type);

            if (this.shopifyCoupon.itemIds?.find((item) => item.type === 'Product')) {
                this.applies = 'Product';
            } else if (this.shopifyCoupon.itemIds?.find((item) => item.type === 'Collection')) {
                this.applies = 'Collection';
            } else {
                this.applies = 'all_products';
            }
            this.search = '';
            if (this.shopifyCoupon.prerequisiteSubtotalRange! > 0) {
                this.minimumPurchase = 'amount';
            } else if (this.shopifyCoupon.prerequisiteQuantityRange! > 0) {
                this.minimumPurchase = 'quantity'
            }            
            if (this.shopifyCoupon.couponSettings != null) {
                this.CouponSettings = this.shopifyCoupon.couponSettings;
                if (!this.integrationsMailEnabled && !this.integrationsSMSEnabled) {
                    this.CouponSettings.enabled = false;
                }
            }
            this.searchProductsCollections();
        } else if (this.editedItem.type === 'coupon' && this.editedItem.settings != null) {
            this.CouponSettings = JSON.parse(this.editedItem.settings);
            if (!this.integrationsMailEnabled && !this.integrationsSMSEnabled) {
                this.CouponSettings.enabled = false;
            }
        }
        this.dialog = true;
    }

    private calcPercent(gravity: any) {
        function sum(accumulator: number, item: Coupon): number {
            return accumulator + (item.gravity == null ? 0 : item.gravity);
        }
        // @ts-ignore
        const allGravity: any = Array.prototype.reduce.call(this.appSlices, sum, 0);
        // all gravities are 0
        if (gravity === 0 && allGravity === 0) {
            return Math.round(100 / 6);
        }
        const procent = Math.round(gravity / allGravity * 100);
        return procent;
    }

    private created() {
        if ((sessionStorage.type === ShopType.Shopify && this.planName !== 'migration') || this.isSuperAdmin) {
            this.sliceTypes.push({ text: i18n.t('campaign.coupon_slices.dynamic'), value: 'dynamic',   selected: false});
        }

        switch(this.appType) {
            case AppType.Wheelio:
            case AppType.Wheelio2:
                this.title = i18n.t('campaign.coupon_slices.coupon_slices');
                this.headers = this.headersWheelio;
                break;
            case AppType.Rollie:
                this.title = i18n.t('campaign.coupon_slices.coupon_slots');
                this.headers = this.headersRollie;
                break;
            case AppType.Scratchy:
                this.title = i18n.t('campaign.coupon_slices.winning_tickets');
                this.headers = this.headersScratchy;
                break;
        }
    }

    private sliceTypeLocal(type: string) {
        return i18n.t(`campaign.coupon_slices.${type}`);
    }

    private typeChanged(value: string) {
        if (value === 'shipping_line') {
            this.shopifyCoupon.valueType = 'percentage';
            this.shopifyCoupon.targetType = 'shipping_line';
            this.shopifyCoupon.allocationMethod = 'each';
            this.placeHolder = '';
            this.valueVisible = false;
            this.appliesChanged('all_products');
        } else {
            this.shopifyCoupon.valueType = value as valueType;
            this.shopifyCoupon.targetType = 'line_item';
            this.shopifyCoupon.allocationMethod = 'across';
            this.valueVisible = true;
            this.suffix = value === 'percentage' ? '%' : this.currency;
            this.placeHolder =  value === 'fixed_amount' ? '0.00' : '';
        }
    }

    private appliesChanged(value: string) {
        this.items = [];
        this.shopifyCoupon.itemIds = [];
        this.search = '';
        this.searchProductsCollections();
        if (value !== 'all_products') {
              this.shopifyCoupon.targetType = 'line_item';
              this.shopifyCoupon.targetSelection = 'entitled';
        } else {
            this.shopifyCoupon.targetSelection = 'all';
            this.applies = 'all_products';
        }
    }

    private changeType(type: string) {
        if (type === 'dynamic') {
            this.editedItem.code = '';
        }
    }

    private getItems() {
        if(this.appType === AppType.Rollie || this.appType === AppType.Scratchy) {
            return this.appSlices.filter((el) => {
                return el.type !== 'losing';
            });
        }
        return this.appSlices;
    }

    private setUsageLimit() {
        if(this.shopifyCoupon.usageLimitEnable) {
            this.shopifyCoupon.usageLimit = 1;
        }
    }

    private validURLLink(str) {
        if(this.editedItem.type !== 'link') {
            return true;
        }
        return this.validURL(str);
    }

    private validURL(str) {        
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(str);
    }

    private expirationChange() {
        if(this.shopifyCoupon.expires === false) {
            if(this.shopifyCoupon.days.toString() === '') { this.shopifyCoupon.days = 0 }
            if(this.shopifyCoupon.hours.toString() === '') { this.shopifyCoupon.hours = 0 }
            if(this.shopifyCoupon.minutes.toString() === '') { this.shopifyCoupon.minutes = 0 }
        }
    }

    get getGravityOptions() {
        let slicesWithGravity = 0;
        this.appSlices.forEach(slice => {
            if (slice.gravity > 0) {
                slicesWithGravity += 1;
            }
        });
        return slicesWithGravity <= 1 ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }

    get couponValueRules() {
        if (this.type === 'percentage') {
            return [this.rules.required, this.rules.positiveInt, this.rules.lessThan101];
        } else {
            return [this.rules.required, this.rules.positiveInt];
        }
    }

    get valueHigherThanUsual() {
        if (this.editedItem.type !== 'dynamic' || !this.shopifyCoupon.value || this.shopifyCoupon.targetType === 'shipping_line') {
            return false;
        }

        if ((this.shopifyCoupon.valueType === ('percentage') as valueType && this.shopifyCoupon.value >= 20) || (this.shopifyCoupon.valueType === ('fixed_amount') as valueType && this.shopifyCoupon.value >= 10 && (this.currency === 'EUR' || this.currency === 'USD' || this.currency === 'CAD'))) {
            return true
        }

        return false;
    }

    private preventWhiteSpace(value: string) {
        return !(value.trim() === '' || value.trim() === null);
    }

    private preventEmoji(value: string) {
        const regexExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
        return !regexExp.test(value)
    }

    private async getProducts() {
        try {
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;
            const response = await this.axios.get(`/api/campaign/getproducts/${this.$route.params.id}/${this.search}`);
            this.items = response.data.data;
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.isLoading = false;
        }
    }

    private async getCollections() {
        try {
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;
            const response = await this.axios.get(`/api/campaign/getcollections/${this.$route.params.id}/${this.search}`);
            this.items = response.data.data;
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.isLoading = false;
        }
    }

    private async searchProductsCollections() {
        if (this.applies === 'Product') {
            await this.getProducts();
        } else if (this.applies === 'Collection') {
            await this.getCollections();
        }
    }

    @Watch('search')
    private async onSearchValueChanged(value: string) {
        if (this.isLoading === false && value != null && value.length >= 3) {
            this.searchProductsCollections();
        }
    }

    private onItemsChange() {
        this.shopifyCoupon.itemIds = this.shopifyCoupon.itemIds.filter((item) => {
            if (typeof item !== 'object' || this.applies === 'all_products') {
                return;
            }
            item.type = this.applies;
            return item;
        });
    }

    get isSuperAdmin() {
        const username = sessionStorage.getItem('username') || '';
        // @ts-ignore
        if (process.env.VUE_APP_SUPER_ADMINS.includes(username)) {
        return true;
        }
        return false;
    }

    get expirationValueRules() {
        if (this.shopifyCoupon.usageLimit && this.shopifyCoupon.usageLimit < 1) {
            return [this.rules.greater0];        
        } else {
            return [];
        }
    }

    get minimumAmountNotification() {
        if(this.applies === 'Collection') {
            return i18n.t('campaign.coupon_slices.minimum_purchase_type.notification.collections');
        } else if (this.applies === 'Product') {
            return i18n.t('campaign.coupon_slices.minimum_purchase_type.notification.products');
        }
        return i18n.t('campaign.coupon_slices.minimum_purchase_type.notification.all');
    }
}
